import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import { EffectFlip, Autoplay } from "swiper/modules";
import imageBack from "../images/backimage.jpg";
import userImage from "../images/user.png";
import "./SliderScreen.css";
import { useLocation } from "react-router-dom";

export default function SliderScreen() {
  const [timeAgo, setTimeAgo] = useState("Just now");
  const [timestamp] = useState(Date.now());
  const [allData, setAllData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const D = searchParams.get("D")?.replace(/"/g, ""); // Remove quotes
  const L = searchParams.get("L")?.replace(/"/g, ""); // Remove quotes

  console.log("D (Department):", D);
  console.log("L (Location):", L);

  useEffect(() => {
    const updateAgo = () => {
      const secondsPassed = Math.floor((Date.now() - timestamp) / 1000);
      setTimeAgo(
        secondsPassed < 60
          ? "Just now"
          : `${Math.floor(secondsPassed / 60)} mins ago`
      );
    };

    const interval = setInterval(updateAgo, 1000);
    return () => clearInterval(interval);
  }, [timestamp]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://powerapi.optigoapps.com/cron/data/v1/daily/A7X9G2LQ3P8D5K1M/employeewise_metal.json"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const text = await response.text(); // Get raw response first
        const result = JSON.parse(text); // Parse JSON
        setAllData(result);
      } catch (err) {
        console.error("Fetch Error:", err);
      } finally {
        console.log("Done...");
      }
    };
    fetchData();
  }, []);

  const filteredData = allData?.map((dayData) =>
    dayData.filter(
      (emp) => emp.INV_MeterialAssignEmp_DeptName === D && emp.location === L
    )
  );

  console.log("filteredData", filteredData);

  const getCurrentDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.toLocaleString("en-US", { month: "short" }); // e.g., "Mar"
    const year = today.getFullYear();
    return `${day} ${month} ${year}`; // Format: 12 Mar 2025
  };

  const getCurrentMonth = () => {
    const today = new Date();
    const month = today.toLocaleString("en-US", { month: "short" }); // e.g., "Mar"
    const year = today.getFullYear();
    return `${month} ${year}`; // Format: 12 Mar 2025
  };

  return (
    <div
      style={{
        backgroundImage: `url(${imageBack})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Swiper
        effect={"flip"}
        grabCursor={true}
        // autoplay={{ delay: 7000, disableOnInteraction: false }}
        navigation={false}
        pagination={false}
        modules={[EffectFlip]}
        // Autoplay
        className="mySwiper"
      >
        {filteredData.map((dayEmployees, dayIndex) => (
          <SwiperSlide key={dayIndex}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "30px",
                    margin: "50px 0px 5px 0px",
                  }}
                >
                  {/* Mar 2025 | {D} ({L}) */}
                  {dayIndex === 1 ? getCurrentDate() : getCurrentMonth()} | {D}{" "}
                  ({L})
                </p>
                <p style={{ margin: "0px" }}>{timeAgo}</p>
              </div>
              <div className="employee_main_div_main">
                <div className="employee_main_div">
                  {dayEmployees.length > 0 ? (
                    dayEmployees
                      .slice()
                      .sort((a, b) => (b.NetRetunWt || 0) - (a.NetRetunWt || 0))
                      .map((empdata, index) => (
                        <div
                          key={index}
                          style={{
                            margin: "20px 50px 5px 20px",
                          }}
                        >
                          <div className="employee_show">
                            <p
                              style={{
                                margin: "0px",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: 600,
                                position: "absolute",
                                top: "0px",
                                left: "5px",
                              }}
                            >
                              {index + 1}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={userImage}
                                className="employee_img"
                                alt="Employee"
                              />
                            </div>
                            <p
                              style={{
                                fontWeight: 500,
                                fontSize: "25px",
                                margin: "0px",
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                right: "15px",
                                top: "23%",
                              }}
                            >
                              {empdata?.NetRetunWt?.toFixed(1)}
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              style={{
                                margin: "2px",
                                fontSize: "13px",
                                color: "gray",
                                textAlign: "center",
                              }}
                            >
                              {empdata?.EmployeeName}
                            </p>
                            <p
                              style={{
                                margin: "2px",
                                fontSize: "13px",
                                color: "gray",
                                textAlign: "center",
                              }}
                            >
                              {empdata?.EmployeeCode}
                            </p>
                            <p
                              style={{
                                margin: "2px",
                                fontSize: "13px",
                                color: "gray",
                                textAlign: "center",
                              }}
                            >
                              Job Count: {empdata?.jobCount}
                            </p>
                          </div>
                        </div>
                      ))
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        color: "red",
                      }}
                    >
                      No data
                    </p>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/effect-flip";
// import "swiper/css/autoplay";
// import { EffectFlip, Autoplay } from "swiper/modules";
// import imageBack from "../images/backimage.jpg";
// import userImgae from "../images/user.png";
// import "./SliderScreen.css";
// import EmployeeData from "./EmployeeData.json";
// import { useLocation } from "react-router-dom";

// export default function SliderScreen() {
//   const [timeAgo, setTimeAgo] = useState("Just now");
//   const [timestamp] = useState(Date.now());
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const selectedDepartment = localStorage.getItem("department");
//   const selectedLocation = localStorage.getItem("Location");

//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);

//   // Get query parameters
//   const D = searchParams.get("D");
//   const L = searchParams.get("L");

//   console.log("D:", D); // Logs "Final Polish"
//   console.log("L:", L); // Logs "M1"

// //     useEffect(() => {
// //       const fetchData = async () => {
// //           try {
// //               const response = await fetch(
// //                   "https://powerapi.optigoapps.com/cron/data/v1/daily/A7X9G2LQ3P8D5K1M/employeewise_metal.json"
// //               );
// //               if (!response.ok) {
// //                   throw new Error(`HTTP error! Status: ${response.status}`);
// //               }
// //               const text = await response.text(); // Get raw response first
// //               const result = JSON.parse(text); // Parse JSON
// //               console.log("API Response:", result);
// //               setData(result);
// //           } catch (err) {
// //               console.error("Fetch Error:", err);
// //               setError(err.message);
// //           } finally {
// //               setLoading(false);
// //           }
// //       };
// //       fetchData();
// //   }, []);

//   useEffect(() => {
//     const updateAgo = () => {
//       const secondsPassed = Math.floor((Date.now() - timestamp) / 1000);
//       if (secondsPassed < 60) {
//         setTimeAgo("Just now");
//       } else {
//         setTimeAgo(
//           `${Math.floor(secondsPassed / 60)} min${
//             Math.floor(secondsPassed / 60) > 1 ? "s" : ""
//           } ago`
//         );
//       }
//     };

//     const interval = setInterval(updateAgo, 1000); // Update every second

//     return () => clearInterval(interval); // Cleanup on unmount
//   }, [timestamp]);

//   return (
//     <div
//       style={{
//         backgroundImage: `url(${imageBack})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <Swiper
//         effect={"flip"}
//         grabCursor={true}
//         autoplay={{ delay: 7000, disableOnInteraction: false }}
//         navigation={false}
//         pagination={false}
//         modules={[EffectFlip]}
//         // modules={[EffectFlip, Autoplay]}
//         className="mySwiper"
//       >
//         <SwiperSlide>
//           <div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 flexDirection: "column",
//                 alignItems: "center",
//               }}
//             >
//               <p
//                 style={{
//                   fontWeight: 500,
//                   fontSize: "30px",
//                   margin: "50px 0px 5px 0px",
//                 }}
//               >
//                 Mar 2025 | {selectedDepartment} ({selectedLocation})
//               </p>
//               <p style={{ margin: "0px" }}>{timeAgo}</p>
//             </div>
//             <div className="employee_main_div">
//               {EmployeeData[0]
//                 ?.slice() // Create a copy to avoid mutating the original array
//                 .sort((a, b) => (b.NetRetunWt || 0) - (a.NetRetunWt || 0)) // Sort in descending order
//                 .map((empdata, index) => {
//                   return (
//                     <div
//                       key={index}
//                       style={{
//                         margin: "20px 50px 5px 20px",
//                       }}
//                     >
//                       <div className="employee_show">
//                         <div
//                           style={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <img src={userImgae} className="employee_img" />
//                           <p style={{ margin: "0px", textAlign: "center" }}>
//                             {index + 1}
//                           </p>
//                         </div>
//                         <p
//                           style={{
//                             fontWeight: 500,
//                             fontSize: "25px",
//                             margin: "0px 0px 10px 0px",
//                             display: "flex",
//                             alignItems: "center",
//                           }}
//                         >
//                           {empdata?.NetRetunWt}
//                         </p>
//                       </div>
//                       <div style={{ display: "flex", flexDirection: "column" }}>
//                         <p
//                           style={{
//                             margin: "2px",
//                             fontSize: "13px",
//                             color: "gray",
//                             textAlign: "center",
//                           }}
//                         >
//                           {empdata?.EmployeeName}
//                         </p>
//                         <p
//                           style={{
//                             margin: "2px",
//                             fontSize: "13px",
//                             color: "gray",
//                             textAlign: "center",
//                           }}
//                         >
//                           {empdata?.EmployeeCode}
//                         </p>
//                         <p
//                           style={{
//                             margin: "2px",
//                             fontSize: "13px",
//                             color: "gray",
//                             textAlign: "center",
//                           }}
//                         >
//                           Job Count: {empdata?.jobCount}
//                         </p>
//                       </div>
//                     </div>
//                   );
//                 })}
//             </div>
//           </div>
//         </SwiperSlide>
//         <SwiperSlide>
//           <div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 flexDirection: "column",
//                 alignItems: "center",
//               }}
//             >
//               <p
//                 style={{
//                   fontWeight: 500,
//                   fontSize: "30px",
//                   margin: "50px 0px 5px 0px",
//                 }}
//               >
//                 12 Mar 2025 | {selectedDepartment} ({selectedLocation})
//               </p>
//               <p style={{ margin: "0px" }}>{timeAgo}</p>
//             </div>
//             <div className="employee_main_div">
//               {EmployeeData[1]
//                 ?.slice() // Create a copy to avoid mutating the original array
//                 .sort((a, b) => (b.NetRetunWt || 0) - (a.NetRetunWt || 0)) // Sort in descending order
//                 .map((empdata, index) => {
//                   return (
//                     <div
//                       key={index}
//                       style={{
//                         margin: "20px 50px 5px 20px",
//                       }}
//                     >
//                       <div className="employee_show">
//                         <div
//                           style={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <img src={userImgae} className="employee_img" />
//                           <p style={{ margin: "0px", textAlign: "center" }}>
//                             {index + 1}
//                           </p>
//                         </div>
//                         <p
//                           style={{
//                             fontWeight: 500,
//                             fontSize: "25px",
//                             margin: "0px 0px 10px 0px",
//                             display: "flex",
//                             alignItems: "center",
//                           }}
//                         >
//                           {empdata?.NetRetunWt}
//                         </p>
//                       </div>
//                       <div style={{ display: "flex", flexDirection: "column" }}>
//                         <p
//                           style={{
//                             margin: "2px",
//                             fontSize: "13px",
//                             color: "gray",
//                             textAlign: "center",
//                           }}
//                         >
//                           {empdata?.EmployeeName}
//                         </p>
//                         <p
//                           style={{
//                             margin: "2px",
//                             fontSize: "13px",
//                             color: "gray",
//                             textAlign: "center",
//                           }}
//                         >
//                           {empdata?.EmployeeCode}
//                         </p>
//                         <p
//                           style={{
//                             margin: "2px",
//                             fontSize: "13px",
//                             color: "gray",
//                             textAlign: "center",
//                           }}
//                         >
//                           Job Count: {empdata?.jobCount}
//                         </p>
//                       </div>
//                     </div>
//                   );
//                 })}
//             </div>
//           </div>
//         </SwiperSlide>
//       </Swiper>
//     </div>
//   );
// }
