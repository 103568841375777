
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './componets/Home/Home';
import SliderScreen from './componets/SliderScreen/SliderScreen';


function App() {
  
  return (
    <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/" element={<SliderScreen />} />
    </Routes>
  </BrowserRouter>
  );
}
export default App;


// import './App.css';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Home from './componets/Home/Home';
// import SliderScreen from './componets/SliderScreen/SliderScreen';

// function App() {
//   return (
//     <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<Home />} />
//       <Route path="/SliderScreen" element={<SliderScreen />} />
//     </Routes>
//   </BrowserRouter>
//   );
// }

// export default App;
